:root {
  --background-color-black: #121317;
  --background-color-dark: #25282D;

  body[color-theme="dark"] {
    --background-color: var(--background-color-dark);
    --background-color-light: white;
    --background-color-rgb: 37,40,45;
    --background-color-darker: var(--background-color-dark);
    --background-color-invert: var(--background-color-light);
    --background-color-invert-rgb: 255, 255, 255;
    --background-color-grey: #35383E;
    --background-color-grey-tint: #474A4F;
    --background-color-grey-shade: var(--background-color-grey-tint);
    --background-color-grey-contrast: var(--background-color-grey);
    --text-color: white;
    --text-color-invert: #121317;
    --text-color-rgb: 255, 255, 255;
    --text-color-06: #fff9;
    --text-color-02: #fff3;

    .ion-page,
    .signin-content {
      background: var(--background-color-black);
    }

    ion-header {
      ion-toolbar {
        --background: var(--background-color);
      }
    }

    ion-footer {
      border-top: 1px solid rgb(255 255 255 / 10%);
    }

    ion-button {
      &.secondary {
        &::part(native) {
          --background: rgb(78 245 245 / 16%);

          color: var(--color-primary);
        }
      }

      &.transparent {
        &::part(native) {
          --background: transparent;

          color: var(--color-primary);
        }
      }

      &.primary,
      &.secondary {
        &[disabled]::part(native) {
          opacity: 0.16;
          color: var(--color-primary);
        }
      }
    }

    ion-tab-bar {
      --border: 1px solid rgb(255 255 255 / 10%);
    }

    .content-block {
      background-color: var(--background-color);
    }

    ion-back-button {
      --background: rgb(78 245 245 / 16%);

      color: var(--color-primary);
    }

    .critical-notification-toast {
      --background: #EFEFF0;
    }

    app-calendar {
      --day-color: white;
      --today-color: white;
      --cancel-button-color: white;
      --done-button-color: white;
      --footer-border-top: 1px solid rgb(255 255 255 / 60%);
    }

    ion-toggle {
      --track-background: rgb(255 255 255 / 30%);
      --handle-background-checked: white;
    }

    ion-radio {
      --color: rgb(255 255 255 / 20%);
    }

    ion-form {  
      ion-input.sc-ion-input-ios-h,
      ion-input.sc-ion-input-md-h,
      ion-textarea.sc-ion-textarea-ios-h,
      ion-textarea.sc-ion-textarea-md-h {
        --background: var(--background-color-grey);
        --color: var(--text-color);
    
        &.has-focus:not([readonly="true"]), &.highlighted {
          border: 2px solid rgba(var(--background-color-invert-rgb), 0.6);
        }
      }
  
    }

    ion-segment {
      --background: var(--background-color-grey) !important;

      ion-segment-button {
        --indicator-color: rgb(78 245 245 / 16%) !important;
      }
    }

    ion-popover {
      --background: var(--background-color-grey-tint);
    }

    ion-checkbox {
      --checkbox-background: var(--background-color-grey);
      --border-color: rgba(var(--text-color-rgb), 0.2);
    }

    app-checkbox {
      --background-color: #696B70;
      --border-color: rgb(255 255 255 / 20%);
    }

    ion-loading {
      --background: var(--background-color-grey-tint);
      --spinner-color: white;
    }
  }
}