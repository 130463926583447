$sf-compact-display-font-path: "./SF-Compact-Display" !default;

@font-face {
  font-family: SFCompactDisplay;
  font-style: normal;
  font-weight: 100;
  src:
    url("#{$sf-compact-display-font-path}/SF-Compact-Display-Ultralight.woff2") format("woff2"),
    url("#{$sf-compact-display-font-path}/SF-Compact-Display-Ultralight.woff") format("woff"),
    url("#{$sf-compact-display-font-path}/SF-Compact-Display-Ultralight.ttf") format("truetype");
}

@font-face {
  font-family: SFCompactDisplay;
  font-style: normal;
  font-weight: 200;
  src:
    url("#{$sf-compact-display-font-path}/SF-Compact-Display-Thin.woff2") format("woff2"),
    url("#{$sf-compact-display-font-path}/SF-Compact-Display-Thin.woff") format("woff"),
    url("#{$sf-compact-display-font-path}/SF-Compact-Display-Thin.ttf") format("truetype");
}

@font-face {
  font-family: SFCompactDisplay;
  font-style: normal;
  font-weight: 300;
  src:
    url("#{$sf-compact-display-font-path}/SF-Compact-Display-Light.woff2") format("woff2"),
    url("#{$sf-compact-display-font-path}/SF-Compact-Display-Light.woff") format("woff"),
    url("#{$sf-compact-display-font-path}/SF-Compact-Display-Light.ttf") format("truetype");
}

@font-face {
  font-family: SFCompactDisplay;
  font-style: normal;
  font-weight: 400;
  src:
    url("#{$sf-compact-display-font-path}/SF-Compact-Display-Regular.woff2") format("woff2"),
    url("#{$sf-compact-display-font-path}/SF-Compact-Display-Regular.woff") format("woff"),
    url("#{$sf-compact-display-font-path}/SF-Compact-Display-Regular.ttf") format("truetype");
}

@font-face {
  font-family: SFCompactDisplay;
  font-style: normal;
  font-weight: 500;
  src:
    url("#{$sf-compact-display-font-path}/SF-Compact-Display-Medium.woff2") format("woff2"),
    url("#{$sf-compact-display-font-path}/SF-Compact-Display-Medium.woff") format("woff"),
    url("#{$sf-compact-display-font-path}/SF-Compact-Display-Medium.ttf") format("truetype");
}

@font-face {
  font-family: SFCompactDisplay;
  font-style: normal;
  font-weight: 600;
  src:
    url("#{$sf-compact-display-font-path}/SF-Compact-Display-Semibold.woff2") format("woff2"),
    url("#{$sf-compact-display-font-path}/SF-Compact-Display-Semibold.woff") format("woff"),
    url("#{$sf-compact-display-font-path}/SF-Compact-Display-Semibold.ttf") format("truetype");
}

@font-face {
  font-family: SFCompactDisplay;
  font-style: normal;
  font-weight: 700;
  src:
    url("#{$sf-compact-display-font-path}/SF-Compact-Display-Bold.woff2") format("woff2"),
    url("#{$sf-compact-display-font-path}/SF-Compact-Display-Bold.woff") format("woff"),
    url("#{$sf-compact-display-font-path}/SF-Compact-Display-Bold.ttf") format("truetype");
}
