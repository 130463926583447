@import "../assets/fonts/geometria";
@import "../assets/fonts/roboto";
@import "../assets/fonts/pt-root-ui";
@import "../assets/fonts/rubik";
@import "../assets/fonts/sf-compact-display";
@import './light';
@import './dark';

body {
  /** primary **/
  --ion-color-primary: #20E5DB;
	--ion-color-primary-rgb: 32,229,219;
	--ion-color-primary-contrast: #000;
	--ion-color-primary-contrast-rgb: 0,0,0;
	--ion-color-primary-shade: #18ADAD;
	--ion-color-primary-tint: #00fff7;

  /** secondary **/
  --ion-color-secondary: #5c80bc;
	--ion-color-secondary-rgb: 92,128,188;
	--ion-color-secondary-contrast: #000;
	--ion-color-secondary-contrast-rgb: 0,0,0;
	--ion-color-secondary-shade: #5c80bc;
	--ion-color-secondary-tint: #5c80bc;

  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #fff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #fff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #fff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #fff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;
  --ion-color-danger-torch-red: #FA1039;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #fff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;
  --ion-color-dark-blue: #2c3541;
  --ion-color-dark-blue-active: #313a47;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #fff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #868e98;
  --ion-color-medium-warm: #999;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #eeedee;
  --ion-color-light-tint: #f5f6f9;
  --ion-color-light-warm: #f2f2f2;
  --ion-color-light-text-disabled: #C3C3C5;


  /** brand **/
  --ion-color-brand: #252b33;
  --ion-color-brand-rgb: 37, 43, 51;
  --ion-color-brand-contrast: #fff;
  --ion-color-brand-contrast-rgb: 255, 255, 255;
  --ion-color-brand-shade: #1e2023;
  --ion-color-brand-tint: #383a3e;
  --ion-color-secondary-selected: #32db64;
  --ion-color-blue: #79b6f8;
  --ion-color-white: #fff;
  --ion-color-white-opacity-20: #ffffff20;
  --ion-color-white-opacity-60: #fff9;
  --ion-color-grey: #999;
  --ion-color-light-grey: #ddd;
  --ion-color-dark-grey: #252b33;
  --ion-color-darker-grey: #4a4e54;
  --ion-color-darker-blue: #191c1f;
  --ion-color-menu-blue: #2c3541;
  --ion-color-black: #121317;
  --ion-color-grey-bg: #eee;

  /** project colors **/
  --color-dark: #121317;
  --color-dark-opacity-20: #12131720;
  --color-dark-opacity-60: #12131799;

  // Fonts
  --ion-font-family: ptrootui, rubik, open sans, sans-serif;
  --ion-text-color: var(--ion-color-black);
  --border-radius: 32px;
  --half-border-radius: calc(var(--border-radius) / 2);

  /* размеры шрифтов */
  --text-size-10: 0.625rem;
  --text-size-11: 0.6875rem;
  --text-size-12: 0.75rem;
  --text-size-13: 0.8125rem;
  --text-size-14: 0.875rem;
  --text-size-15: 0.9375rem;
  --text-size-16: 1rem;
  --text-size-18: 1.125rem;
  --text-size-19: 1.1875rem;
  --text-size-20: 1.25rem;
  --text-size-21: 1.3125rem;
  --text-size-22: 1.375rem;
  --text-size-24: 1.5rem;
  --text-size-26: 1.625rem;
  --text-size-28: 1.75rem;

  /** paddings **/
  --ion-padding: 10px;

  ::slotted(ion-icon) {
    color:  var(--text-color) !important;
  }
}

.ion-color-primary {
	--ion-color-base: var(--ion-color-primary);
	--ion-color-base-rgb: var(--ion-color-primary-rgb);
	--ion-color-contrast: var(--ion-color-primary-contrast);
	--ion-color-contrast-rgb: var(--ion-color-primary-contrast-rgb);
	--ion-color-shade: var(--ion-color-primary-shade);
	--ion-color-tint: var(--ion-color-primary-tint);
}
