/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

/* Swiper JS styles */
@import "swiper/scss";
@import "@ionic/angular/css/ionic-swiper";
@import "swiper/scss/pagination";
@import "swiper/scss/autoplay";
@import "swiper/scss/virtual";

// фикс на iOS, чтобы контент не вылезал за шторку
ion-app {
  margin-top: env(safe-area-inset-top);
  margin-bottom: env(safe-area-inset-bottom);
  height: calc(100% - env(safe-area-inset-top) - env(safe-area-inset-bottom)) !important;
}

ion-header {
  &.header-md {
    box-shadow: none;
    border-bottom: 1px solid var(--background-color-grey);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  ion-toolbar {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    align-items: center;
    
    &:first-of-type {
      padding-left: 15px;
      padding-right: 15px;
      min-height: 60px;
    }

    .header-title {
      display: inline-block;
      width: 100%;
      text-align: center;
      color: var(--text-color);
      font-size: 1.125rem;
      font-weight: 500;
      text-wrap: balance;
    }
  }

  :not(:has(:nth-child(3))) {
    ion-buttons {
      display: grid;
      grid-template-columns: 50px 1fr 50px;

      .header-title {
        text-align: center;
      }
    }
  }
}

ion-accordion-group>ion-accordion ion-item[slot="header"] {
  ion-icon[slot="end"] {
    color: var(--text-color) !important;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.tab-bar.ios {
  padding-bottom: 0;
}

// с помощью этого фиксим белый экран на iOS <= 13.3
.ion-page {
  width: 100%;
  height: 100%;
}

// fix работы ссылок для iOS <= 15.0
a {
  pointer-events: initial;
}

h1, h2 {
  margin: 0 0 14px;
}

// в ионике 7 этот атрибут не работает, поэтому тут восстанавливаем его работу
[hidden] {
  display: none !important;
}

// отключаем иконку с "часами" для инпутов с типом "time"
input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
}

// стили для скроллбара, который должен отображаться принудительно
.force-scrollbar {
  &::-webkit-scrollbar {
    width: 12px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #c2c2c2;
  }
}

ion-toast {
  --background: var(--background-color-invert);
  --color: var(--text-color-invert);
  --border-radius: 14px;
  --box-shadow: none;

  font-weight: 500;
  font-size: 0.875rem;
  text-align: center;
  transform: translateY(-57px);

  &.primary {
    --background: var(--background-color-grey);
    --color: var(--color-primary);

    text-align: inherit;
    top: 120px;

    &::part(container) {
      .toast-content {
        padding-top: 16px;
        padding-bottom: 16px;
      }
    }

    &::part(icon) {
      font-size: 1.5rem;
    }

    &::part(message) {
      font-size: 0.938rem;
      font-weight: 700;
      padding-top: 2px;
      padding-bottom: 2px;
      text-transform: uppercase;
    }
  }

  &.popup {
    &::part(container) {
      flex-direction: column-reverse;

      .toast-content {
        display: none;
      }
    }

    &::part(message) {
      text-align: center;
      padding-bottom: 24px;
      font-weight: 500;
      white-space: pre-line;
    }

    &::part(cancel) {
      font-size: 1.5rem;
      opacity: 0.6;
    }
  }

  &.announcement-info {
    --color: #5aacd8;
    --button-color: #5aacd8;
  }

  &.announcement-issue {
    --color: #ffab00;
    --button-color: #ffab00;
  }
}

// включаем возможность скролла страницы, если палец попадает на input
ion-input,
app-notification-card {
  touch-action: pan-y !important;
}

.selection-input {
  .native-input.sc-ion-input-md,
  .native-input.sc-ion-input-ios {
    cursor: pointer;
  }
}

// включаем возможность переноса текста внутри ion-select-popover
.sc-ion-select-popover-md,
.sc-ion-select-popover-ios {
  white-space: normal !important;
}

// включаем возможность переноса текста в ion-alert
.alert-tappable.sc-ion-alert-md,
.alert-tappable.sc-ion-alert-ios {
  height: initial;
  contain: initial;
}

.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
  white-space: normal;
  height: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

ion-modal {
  --background: transparent;

  margin-bottom: 57px;

  &.full-view {
    width: 100%;
    height: 100%;
    margin: 0;
  }

  &.max-content {
    --width: max-content;
    --height: max-content;
  }

  &.auto-height {
    --height: auto;
    --border-radius: 20px;

    margin: 0;

    &::part(handle) {
      width: 60px;
      height: 5px;
      margin: 10px auto;
      background-color: rgba(var(--background-color-invert-rgb), 0.1);
    }

    .ion-page {
      height: auto;
    }

    .content-block {
      padding: 30px 0;
      background-color: var(--ion-color-custom-grey);
      height: max-content;
      border-radius: 0;
      margin-bottom: 0;
  
      .control-buttons {
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin-top: 20px;
        padding: 0 15px;
  
        ion-button {
          width: 100%;
        }
      }
    }
  }

  .ion-page {
    padding: 0;
  }

  &:not(.auto-height) {
    &::part(content) {
      top: 0;
      position: absolute;
    }
  
    .content-block {
      height: 100%;
    }
  }
}

.content-block {
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-radius: 15px;
  background-color: var(--background-color);
  color: var(--text-color);
  margin-bottom: 5px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    ion-icon[name="chevron-forward-outline"] {
      font-size: 1.6rem;
    }
  }

  h1 {
    font-family: Rubik, sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 1.375rem;
    margin: 0;
    color: var(--text-color);
  }

  h2 {
    font-family: Rubik, sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    font-size: var(--text-size-20);
    margin: 0;
    color: var(--text-color);
    line-height: 25px;
    letter-spacing: -0.03em;
  }


  h3 {
    font-family: Rubik, sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    font-size: var(--text-size-15);
    margin: 0;
    color: var(--text-color);
  }

  &:has(.content-block) {
    padding: 15px 0;
  }

  .content-block {
    background-color: white;
  }
}

.step-bar {
  width: auto;
  flex: 1;
  border-radius: 32px;
  display: flex;
  align-items: center;
  color: var(--color-primary);
  padding: 10px 16px;
  background-color: rgba(var(--color-primary-rgb), 0.16);
  font-weight: 700;

  .step {
    display: flex;
    align-items: center;
    gap: 4px;
    text-transform: uppercase;
  }

  .all-steps-count {
    margin-left: auto;
    opacity: 0.6;
  }
}

ion-modal.stack-modal {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
}

ion-alert {
  .alert-wrapper.sc-ion-alert-md {
    border-radius: 8px;
    position: relative;

    .alert-message {
      text-align: center;
      font-weight: 500;
      color: var(--text-color);
      font-size: 1.25rem;
    }
  
    .alert-button-group {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 0 24px;

      button {
        font-weight: 700;
        font-size: 1.25rem;
      }
    }
  }

  .text-grey {
    color: var(--ion-color-grey);
  }

  .text-primary {
    color: var(--color-primary);
    font-weight: bold;
  }

  &.primary {
    --background: var(--background-color);
  }
}

@for $i from 1 through 5 {
  .text-ellipsis-#{$i}-row {
    // stylelint-disable-next-line property-no-vendor-prefix
    -moz-box-orient: vertical;
    // stylelint-disable-next-line property-no-vendor-prefix
    -webkit-box-orient: vertical;
    // stylelint-disable-next-line value-no-vendor-prefix
    display: -moz-box;
    // stylelint-disable-next-line value-no-vendor-prefix
    display: -webkit-box;
    -webkit-line-clamp: $i;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

swiper-container {
  width: 100%;
  padding-left: 5px;

  &::part(container) {
    padding-bottom: 30px;
  }
    
  &::part(bullet-active) {
    width: 30px;
    height: 4px;
    background-color: var(--background-color-invert);
    border-radius: 6px;
  }

  &::part(bullet) {
    width: 4px;
    height: 4px;
    background-color: rgba(var(--background-color-invert-rgb), 0.5);
  }
}

.notification-toast {
  cursor: pointer;
}

.critical-notification-toast {
  &::part(container) {
    text-align: start;
    align-items: start;
  }

  &::part(icon) {
    margin-top: 12px;
    width: 24px;
    height: 24px;
  }

  &::part(header) {
    margin-top: -34px;
    margin-left: 32px;
    font-size: 0.938rem;
    font-weight: 700;
    text-transform: uppercase;
  }

  &::part(message) {
    margin-top: 10px;
  }

  &::part(button) {
    width: 48%;
    height: 50px;
    margin-left: 2%;
    margin-bottom: 8px;
    border-radius: 32px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #121317;
    font-weight: 700;
  }
}

ion-icon, img {
  &.invert-color {
    filter: invert(1);
  }
}

ion-title {
  text-transform: uppercase;
  font-size: 1.625rem;
  padding: 0;
  margin: 0;
}

ion-segment {
  --background: var(--background-color);

  border-radius: 8px;

  &-button {
    &.segment-button-checked {
      --color: var(--ion-color-white);
    }

    --border-radius: 8px;
    --indicator-color: rgb(78 245 245 / 16%);
    --color: var(--text-color);
    --background-checked: var(--color-primary-shade);
    --border-color: var(--color-primary-shade);
    --indicator-box-shadow: none;

    height: 36px;
    text-transform: uppercase;
    font-size: var(--text-size-15);
    font-weight: 500;
    margin: 2px;

    &::part(indicator) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.dark {
    ion-segment-button {
      --indicator-color: rgb(78 245 245 / 16%);
      --color: var(--ion-color-white);
      --background-checked: rgba(#4EF5F5, 0.16);
      --border-color: rgba(#4EF5F5, 0.16);
      --indicator-box-shadow: none;
    }
  }
}

ion-content {
  --background: transparent;
  --padding-top: 5px;
}

ion-list {
  &.list-md, &.list-ios {
    background: transparent;
  }

  ion-item {
    --padding-start: 0;
    --inner-padding-end: 0;
  }
}

ion-searchbar {
  &.sc-ion-searchbar-ios-h {
    --background: var(--background-color-grey);
    --border-radius: 10px;

    padding-inline: 5px 5px;
    padding-top: 0;
    padding-bottom: 0;
    color: var(--text-color);

    .searchbar-search-icon.sc-ion-searchbar-ios {
      inset-inline-start: 15px;
      opacity: 0.6;
      color: var(--text-color);
    }

    .searchbar-input.sc-ion-searchbar-ios {
      padding-inline-start: 48px !important;
    }

    .searchbar-clear-button.sc-ion-searchbar-ios {
      inset-inline-end: 10px;
    }

    .searchbar-clear-icon.sc-ion-searchbar-ios {
      width: 24px;
      color: var(--text-color);
    }

    .searchbar-input {
      padding: 18px 15px;
      font-weight: 500;

      &::placeholder {
        text-transform: uppercase;
        opacity: 0.6;
        font-weight: 500;
        font-size: 0.875rem;
      }
    }

    &.searchbar-has-value {
      .searchbar-input-container.sc-ion-searchbar-ios {
        ion-icon.searchbar-search-icon {
          display: none;
        }
      }

      .searchbar-input.sc-ion-searchbar-ios {
        padding-inline-start: 15px !important;
      }
    }
  }
}

ion-input.password {
  --border-radius: 10px;
  --background: var(--background-color-grey);
}

ion-input.ellipsis {
  .native-input {
    max-width: 90%;
    text-overflow: ellipsis;
  }
}

form,
ion-form {
  ion-input.sc-ion-input-ios-h,
  ion-input.sc-ion-input-md-h,
  ion-textarea.sc-ion-textarea-ios-h,
  ion-textarea.sc-ion-textarea-md-h {
    --background: var(--background-color-grey);
    --padding-start: 15px;
    --padding-end: 15px;
    --padding-top: 22px;
    --padding-bottom: 22px;
    --border-radius: 10px;
    --border-width: 0;

    border-radius: 10px;
    font-weight: 500;
    font-size: 0.875rem;
    text-transform: uppercase;

    &:has([slot="end"]>ion-text) {
      &[slot="end"] {
        display: flex;
        width: 100%;
      }

      .label-text-wrapper {
        min-width: 60%;
      }

      .native-input {
        max-width: 0;
      }
    }

    &.hide-label {
      .label-text-wrapper {
        display: none;
      }
    }
    
    &.has-focus:not([readonly]), &.highlighted {
      .textarea-wrapper,
      .input-wrapper {
        border: 2px solid rgba(var(--text-color-rgb), 20%);
      }
    }

    &.input-label-placement-floating {
      --padding-top: 5px;
      --padding-bottom: 18px;

      .native-input, .covered-value {
        margin-top: 5px;
      }

      .native-input {
        min-width: 60%;
      }

      ion-icon {
        transform: translateY(-2px);
      }
    }

    .label-text-wrapper {
      opacity: 0.6;
      min-width: 85%;
      text-transform: uppercase;
    }

    ion-icon {
      position: absolute;
      right: 0;
      opacity: 0.6;
      z-index: 10;

      &.full-opacity {
        opacity: 1;
        font-size: 1.5rem;
      }
    }

    &.input-disabled {
      opacity: 1;
      color: rgba(var(--text-color-rgb), 0.2);
    }

    .input-bottom {
      padding-inline-start: unset;

      .error-text {
        display: unset;
        text-transform: none;
        font-size: 0.75rem;
        color: var(--color-danger);
      }
    }

    &.without-error-padding {
      .input-bottom {
        padding-top: 0;
      }
    }

    &.ion-invalid.ion-touched {
      .input-bottom {
        padding-right: 0;
      }

      .label-text-wrapper {
        color: var(--color-danger);
        opacity: 1;
      }
    }
  }

  ion-input.ion-invalid {
    .input-wrapper {
      .label-text-wrapper {
        opacity: 1;
      }
    }
  }

  ion-textarea.sc-ion-textarea-ios-h,
  ion-textarea.sc-ion-textarea-md-h {
    text-transform: none;

    .label-text-wrapper.sc-ion-textarea-ios {
      margin-top: 6px;
    }

    &:not(.has-focus) {
      &:not(.has-value) {
        height: 60px;
        margin-bottom: 20px;

        .label-text-wrapper.sc-ion-textarea-ios {
          margin-top: 4px;
        }
      }
    }
  }

  ion-item {
    --border-radius: 10px;

    ion-label.sc-ion-label-ios-h {
      text-transform: uppercase;
      opacity: 0.6;
      font-weight: 500;
    }
  }
}

ion-popover {
  color: var(--text-color);

  &.auto-width {
    --width: auto;
  }

  &.highlighted-popover {
    margin-top: 5px;

    &::part(content) {
      border-radius: 13px;
      box-shadow: none;
      border: 2px solid var(--background-color-invert);
    }
  }

  &.reference-popover {
    --backdrop-opacity: 0;
    --width: 100%;
    --offset-x: -4%;
    --offset-y: -10%;
    --box-shadow: none;

    margin: 10px;

    &::part(content) {
      border-radius: 8px;
    }
  }

  &.page-popover {
    --backdrop-opacity: 0;
    --width: 100%;
    --offset-x: -3%;
    --offset-y: 10%;
    --box-shadow: none;

    margin: 10px;

    &::part(content) {
      border-radius: 8px;
    }
  }
}

ion-checkbox {
  --border-color-checked: var(--color-primary);
  --checkbox-background-checked: var(--color-primary);
  --checkmark-color: var(--text-color-invert);
  --border-width: 1px;

  font-size: var(--text-size-15);
  font-weight: 500;
}

ion-toggle {
  --track-background-checked: var(--color-primary-shade);
}

ion-radio {
  --color-checked: var(--color-primary);
  --border-width: 1px;

  &::part(mark) {
    width: 12px;
    height: 12px;
  }
}

ion-back-button {
  --border-radius: 32px;
  --padding-end: 0;
  --padding-start: 0;

  display: flex !important;
  align-items: center;
  width: 50px;
  height: 40px;
  min-height: 40px;

  &::part(native) {
    --padding-end: 0;
    --padding-start: 0;
    --padding-bottom: 0;
    --padding-top: 0;

    width: 50px;
    height: 40px;
    min-height: 40px;
  }
}

ion-button {
  --box-shadow: none;
  --background-hover-opacity: 0;
  --background-focused-opacity: 0;
  --background-activated-opacity: 0;

  margin: 0;
  width: max-content;
  font-weight: 700;
  font-size: var(--text-size-18);
  text-transform: uppercase;
  letter-spacing: normal;

  &::part(native) {
    --padding-end: 20px;
    --padding-start: 20px;
    --padding-bottom: 15px;
    --padding-top: 15px;
    --border-radius: 16px;
  }

  &:has(ion-icon+ion-text) {
    ion-icon {
      margin-right: 10px;
    }
  }

  &:has(ion-icon:only-child) {
    width: 50px;
    height: 40px;
    margin: 0;

    &::part(native) {
      --border-radius: 32px !important;
      --padding-end: 0;
      --padding-start: 0;
      --padding-bottom: 0;
      --padding-top: 0;

      width: 50px;
      height: 40px;
      min-height: auto;
      min-width: auto;
    }
  }

  &:has(.button-content) {
    &::part(native) {
      --border-radius: 10px;
      --padding-end: 0;
      --padding-start: 0;
      --padding-bottom: 0;
      --padding-top: 0;
    }

    .button-content {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      padding: 16px 22px;
      text-transform: uppercase;

      ion-icon {
        zoom: 1.8;
      }
    }
  }

  &.primary {
    --background: var(--color-primary-shade);

    color: white;
    width: -webkit-fill-available;
    width: -moz-available;

    ion-icon {
      width: 25px;
      height: 25px;
    }

    ion-text {
      font-size: var(--text-size-15);
    }
  }

  &.secondary {
    width: 100%;
    
    ion-icon {
      width: 24px;
      height: 24px;
    }

    ion-text {
      font-size: 0.938rem;
    }
  }

  &.round {
    border-radius: 32px;

    &::part(native) {
      --border-radius: 32px;
    }

    ion-text {
      font-size: var(--text-size-15);
    }
  }

  &.half-round {
    border-radius: 16px;

    &:not(:has(ion-icon)) {
      ion-text {
        font-size: var(--text-size-18);
      }
    }
  }

  &.bordered {
    --padding-start: 16px;
    --padding-end: 16px;
    --padding-top: 10px;
    --padding-bottom: 10px;
  }

  &.large {
    font-size: 0.938rem;

    &::part(native) {
      --padding-bottom: 18px;
      --padding-top: 18px;
    }

    ion-icon {
      width: 24px;
      height: 24px;
    }

    ion-text {
      font-size: 1.125rem;
      font-weight: 700;
    }
  }

  &.back-button {
    --color: var(--color-primary);
    --background: rgba(var(--color-primary-rgb), 0.16);

    font-size: 1.1rem;
  }

  ion-icon {
    width: 24px;
    height: 24px;
  }
}

ion-footer {
  background-color: var(--background-color);

  &.footer-md {
    box-shadow: none;
  }

  ion-buttons {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  ion-button {
    height: 60px;
  }
}

.invisible {
  display: none;
}

/* кастомные иониковские цвета */

:root {
  --ion-color-custom-dark: #2d3542;
  --ion-color-custom-dark-rgb: 45, 53, 66;
  --ion-color-custom-dark-contrast: #fff;
  --ion-color-custom-dark-contrast-rgb: 255, 255, 255;
  --ion-color-custom-dark-shade: #282f3a;
  --ion-color-custom-dark-tint: #424955;
  --ion-color-custom-grey: #EFEFF0;
  --ion-color-custom-grey-rgb: 61, 69, 83;
  --ion-color-custom-grey-contrast: #fff;
  --ion-color-custom-grey-contrast-rgb: 255, 255, 255;
  --ion-color-custom-grey-shade: #363d49;
  --ion-color-custom-grey-tint: #505864;
  --ion-color-custom-green: #2affee;
  --ion-color-custom-green-rgb: 42, 255, 238;
  --ion-color-custom-green-contrast: #000;
  --ion-color-custom-green-contrast-rgb: 0, 0, 0;
  --ion-color-custom-green-shade: #25e0d1;
  --ion-color-custom-green-tint: #3ffff0;
  --ion-color-custom-orange: #f26122;
  --ion-color-custom-orange-rgb: 242, 97, 34;
  --ion-color-custom-orange-contrast: #000;
  --ion-color-custom-orange-contrast-rgb: 0, 0, 0;
  --ion-color-custom-orange-shade: #d5551e;
  --ion-color-custom-orange-tint: #f37138;
}

.ion-color-custom-dark {
  --ion-color-base: var(--ion-color-custom-dark);
  --ion-color-base-rgb: var(--ion-color-custom-dark-rgb);
  --ion-color-contrast: var(--ion-color-custom-dark-contrast);
  --ion-color-contrast-rgb: var(--ion-color-custom-dark-contrast-rgb);
  --ion-color-shade: var(--ion-color-custom-dark-shade);
  --ion-color-tint: var(--ion-color-custom-dark-tint);
}

.ion-color-custom-grey {
  --ion-color-base: var(--ion-color-custom-grey);
  --ion-color-base-rgb: var(--ion-color-custom-grey-rgb);
  --ion-color-contrast: var(--ion-color-custom-grey-contrast);
  --ion-color-contrast-rgb: var(--ion-color-custom-grey-contrast-rgb);
  --ion-color-shade: var(--ion-color-custom-grey-shade);
  --ion-color-tint: var(--ion-color-custom-grey-tint);
}

.ion-color-custom-green {
  --ion-color-base: var(--ion-color-custom-green);
  --ion-color-base-rgb: var(--ion-color-custom-green-rgb);
  --ion-color-contrast: var(--ion-color-custom-green-contrast);
  --ion-color-contrast-rgb: var(--ion-color-custom-green-contrast-rgb);
  --ion-color-shade: var(--ion-color-custom-green-shade);
  --ion-color-tint: var(--ion-color-custom-green-tint);
}

.ion-color-custom-orange {
  --ion-color-base: var(--ion-color-custom-orange);
  --ion-color-base-rgb: var(--ion-color-custom-orange-rgb);
  --ion-color-contrast: var(--ion-color-custom-orange-contrast);
  --ion-color-contrast-rgb: var(--ion-color-custom-orange-contrast-rgb);
  --ion-color-shade: var(--ion-color-custom-orange-shade);
  --ion-color-tint: var(--ion-color-custom-orange-tint);
}

.textarea-wrapper-inner.sc-ion-textarea-ios {
  min-height: unset;
}

.native-wrapper.sc-ion-textarea-ios textarea.sc-ion-textarea-ios {
  padding: 0;
}