:root {
  --background-color-grey: #EFEFF0;
  --color-primary: #2AFFEE;
  --color-primary-rgb: 42, 255, 238;
  --color-primary-shade: #18ADAD;
  --color-primary-shade-rgb: 24, 173, 173;
  --color-danger: #FE2A50;
  --color-danger-rgb: 254, 42, 80;
  --color-warning: #EC8E49;
  --color-warning-rgb: 236, 142, 73;
  --color-yellow: #FCD139;
  --color-yellow-shade: #CCAD3C;
  --color-blue-rgb: 78, 245, 245;

  body[color-theme="light"] {
    --color-primary: var(--color-primary-shade);
    --background-color: white;
    --background-color-light: var(--background-color-grey);
    --background-color-rgb: 255, 255 ,255;
    --background-color-darker: #D9D9D9;
    --background-color-invert: var(--background-color-dark);
    --background-color-invert-rgb: 37, 40, 45;
    --background-color-grey-tint: #EFEFF0;
    --background-color-grey-shade: var(--background-color);
    --background-color-grey-contrast: white;
    --text-color: #121317;
    --text-color-invert: white;
    --text-color-rgb: 0, 0, 0;
    --text-color-06: #12131799;
    --text-color-02: #12131733;

    .ion-page,
    .signin-content {
      background: var(--background-color-grey);
    }

    ion-header {
      ion-toolbar {
        --background: var(--background-color);
      }
    }

    ion-footer {
      border-top: 1px solid rgb(18 19 23 / 10%);
    }

    ion-button {
      &.secondary {
        &::part(native) {
          --background: var(--background-color-grey);

          color: var(--text-color);
        }
      }

      &.transparent {
        &::part(native) {
          --background: transparent;

          color: var(--text-color);
        }
      }

      &.primary,
      &.secondary {
        &[disabled]::part(native) {
          --background: var(--background-color-grey);

          color: var(--ion-color-light-text-disabled);
        }
      }
    }

    ion-tab-bar {
      --border: 1px solid rgb(18 19 23 / 10%);
    }

    .content-block {
      background-color: var(--background-color);
    }

    ion-back-button {
      --background: var(--background-color-grey);

      color: var(--text-color);
    }

    .critical-notification-toast {
      --background: #474A4F;
    }

    ion-radio {
      --color: rgb(18 19 23 / 20%);

      background-color: var(--background-color-grey-contrast);
      border-radius: 50%;
    }

    app-calendar {
      --cancel-button-color: transparent;
    }

    app-checkbox {
      --border-color: rgb(18 19 23 / 20%);
    }
  }
}